<template>
  <div class="container">
    <PublicHeader :totalName="$t('FundingDetails').title" />
    <van-pull-refresh
      v-model="refreshing"
      :pulling-text="$t('public').pullingText"
      :loosing-text="$t('public').ReleaseText"
      :loading-text="$t('public').loadingText"
      :success-text="$t('public').RefreshSucceeded"
      @refresh="onRefresh"
    >
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div
          class="item"
          v-for="(lItem, lIndex) in list"
          :key="lIndex"
          @click="handleClick(lItem)"
        >
          <div class="top">
            <span
              class="type"
              :class="{
                up: lItem.ChangeType === 'Win' && lItem.IsWin === 'true',
                down: lItem.ChangeType === 'Win' && lItem.IsWin === 'false',
              }"
              >{{ $t('FundingDetails')[lItem.ChangeType] }}</span
            >
            <span
              class="status"
              :class="{
                success: lItem.TransferStatus === 'Success',
                fail: lItem.TransferStatus === 'Fail',
              }"
              >{{ $t('statusLang')[lItem.TransferStatus] }}</span
            >
          </div>
          <div class="bottom">
            <span class="money"
              >{{ basicConfig.Symbol }}{{ lItem.Amount }}
            </span>
            <span>{{ lItem.CreateTime }}</span>
          </div>
          <div class="Remark" v-if="lItem.ChangeType === 'Lower'">
            {{ lItem.Remark }}
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-dialog v-model="show" title="详情">
      <div class="details">{{ Remark }}</div>
    </van-dialog>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import transactionApi from '@/api/transaction'
import { balanceChangeTypeArr, TransferStatusArr } from '@/utils/validate'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      balanceChangeTypeArr,
      TransferStatusArr,
      Remark: null,
      show: false,
      list: [],
      form: {
        page: 1,
        size: 10,
      },
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    async getList() {
      const res = await transactionApi.amounts(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }

      this.loading = false
    },
    onRefresh() {
      this.form.page = 1
      this.finished = false
      this.loading = true
      setTimeout(() => {
        this.list = []
        this.getList()
      }, 100)
    },
    handleClick(item) {
      if (item.ChangeType !== 'Upper' && item.ChangeType !== 'Lower') {
        this.Remark = item.Remark
        this.show = true
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  text-align center
  .list-box
    height calc(100vh - 50px)
    overflow scroll
  .item
    padding 5px 10px
    font-size 13px
    line-height 1.5
    color #747269
    .top, .bottom
      display flex
      justify-content space-between
      .type, .money
        font-weight bold
        font-size 15px
        color #a79d71
    .status
      color #ff976a
    .fail
      color #ff0000
    .success
      color #fae397
    .Remark
      text-align left
      color #ced1d6
  .van-dialog
    background #1F1F1F
    padding 0 10px
    font-size 13px
    color #fff
    >>> .van-dialog__header
      font-size 16px
      color #ebe5cd
      padding-top 12px
    >>> .van-dialog__content
      padding 5px
    >>> .van-button--default
      background #1F1F1F
      color #ebe5cd!important
    .details
      text-align left
      line-height 1.5
      padding 7.5px 0
.down
  color #31ad91 !important
</style>
