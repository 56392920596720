var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('PublicHeader',{attrs:{"totalName":_vm.$t('FundingDetails').title}}),_c('van-pull-refresh',{attrs:{"pulling-text":_vm.$t('public').pullingText,"loosing-text":_vm.$t('public').ReleaseText,"loading-text":_vm.$t('public').loadingText,"success-text":_vm.$t('public').RefreshSucceeded},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{ref:"list",staticClass:"list-box",attrs:{"finished":_vm.finished,"loading-text":_vm.$t('public').loadingText,"finished-text":_vm.$t('public').noMoreData},on:{"load":_vm.getList},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(lItem,lIndex){return _c('div',{key:lIndex,staticClass:"item",on:{"click":function($event){return _vm.handleClick(lItem)}}},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"type",class:{
              up: lItem.ChangeType === 'Win' && lItem.IsWin === 'true',
              down: lItem.ChangeType === 'Win' && lItem.IsWin === 'false',
            }},[_vm._v(_vm._s(_vm.$t('FundingDetails')[lItem.ChangeType]))]),_c('span',{staticClass:"status",class:{
              success: lItem.TransferStatus === 'Success',
              fail: lItem.TransferStatus === 'Fail',
            }},[_vm._v(_vm._s(_vm.$t('statusLang')[lItem.TransferStatus]))])]),_c('div',{staticClass:"bottom"},[_c('span',{staticClass:"money"},[_vm._v(_vm._s(_vm.basicConfig.Symbol)+_vm._s(lItem.Amount)+" ")]),_c('span',[_vm._v(_vm._s(lItem.CreateTime))])]),(lItem.ChangeType === 'Lower')?_c('div',{staticClass:"Remark"},[_vm._v(" "+_vm._s(lItem.Remark)+" ")]):_vm._e()])}),0)],1),_c('van-dialog',{attrs:{"title":"详情"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"details"},[_vm._v(_vm._s(_vm.Remark))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }